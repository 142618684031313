<!--
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2022-08-12 14:59:53
 * @LastEditors: liuxia
 * @LastEditTime: 2022-08-17 09:20:55
-->
<template>
  <div class="help-center">
    <div
      class="single"
      v-for="(item, index) in videoList"
      :key="index"
      @click="openVideo(item)"
    >
      <div class="header">
        <img :src="item.image" alt="" />
        <img class="head-img" :src="item.headImg" alt="" />
        <img class="play-icon" src="@/assets/img/play-circle.png" alt />
        <div class="mask-tool"></div>
      </div>
      <div class="content">
        <span class="name">{{ item.name }}</span>
        <span class="date">2022.08.12</span>
      </div>
    </div>
    <a-modal
      class="edit-dialog"
      width="55%"
      :visible.sync="visible"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
      @cancel="noHandle"
    >
      <video id="videoPlayer" width="100%" height="500" controls autoplay>
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'index',
  data() {
    return {
      videoList: [
        {
          url: 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/video/video1.mp4',
          name: '云算房操作讲解',
          image:
            'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/shipin%20picture/jiangjie-1.png',
          headImg:
            'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/shipin%20picture/jiangjie.png',
        },
        {
          url: 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/video/video2.mp4',
          name: '云算房功能介绍',
          image:
            'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/shipin%20picture/jieshao-1.png',
          headImg:
            'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/shipin%20picture/jieshoa.png',
        },
        {
          url: 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/video/video3.mp4',
          name: '云算房产品宣传',
          image:
            'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/shipin%20picture/xuanchuan-1.png',
          headImg:
            'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/shipin%20picture/xuanchuan.png',
        },
      ],
      visible: false,
      videoUrl: '',
    };
  },
  methods: {
    noHandle() {
      this.visible = false;
      let videoPlayer = document.getElementById('videoPlayer');
      console.log('videoPlayer', videoPlayer);
      videoPlayer.pause();
    },
    openVideo(item) {
      this.videoUrl = item.url;
      this.visible = true;
      // this.$nextTick(() => {
      //   let videoPlayer = document.getElementById('videoPlayer');
      //   console.log('videoPlayer', videoPlayer, this.videoUrl)
      //   videoPlayer.play();
      // })
    },
  },
});
</script>

<style lang="less" scoped>
.help-center {
  display: flex;
  .single {
    width: 356px;
    margin-right: 57px;
    .header {
      position: relative;
      img {
        width: 100%;
      }
      .head-img {
        position: absolute;
        top: 50px;
        left: 20px;
        width: 208px;
        height: 90px;
        z-index: 3;
      }
      .play-icon {
        position: absolute;
        right: 20px;
        bottom: 18px;
        width: 62px;
        height: 62px;
        z-index: 3;
      }
      &:hover {
        .mask-tool {
          display: block;
          background: #000000;
          opacity: 0.3;
        }
      }
      .mask-tool {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: #000000;
        opacity: 0.5;
      }
    }
    .content {
      padding: 15px;
      background: #ffffff;
      box-shadow: 0 3px 18px 1px rgba(0, 0, 0, 0.19);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 14px;
        color: #333333;
      }
      .date {
        font-size: 12px;
        color: #95a2a5;
      }
    }
  }
}
</style>
